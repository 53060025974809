.App {
  text-align: center;
  height: 100vh;
  background-color: var(--mapBackground);
}

.App-header {
  background-color: var(--black);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  padding: 2vh;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  z-index: 10;
  height: 8%;
  user-select: none;
}

.App-header p {
  font-size: 6vh;
}

.festu-logo {
  height: 5vh;
  margin-right: 2vh;
}

.menu-button {
  background-color: transparent;
  color: var(--white);
  border: none;
  cursor: pointer;
}

.menu-button span {
  height: 10vh;
  width: 10vh;
  font-size: 6vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map {
  max-height: 83vh;
}
