body {
  margin: 0;
  font-family: "Questrial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  --red: red;
  --white: #e1ecf7;
  --grey: #9d8189;
  /* --black: #240115; */
  /* --black: #500207;
  --mapBackground: #77030b;
  --mapColor: #f5efe8; */

  --black: #0f3a57;
  --mapColor: #e1ecf7;
  --mapBackground: #185d8b;
  --mapOutside: #aecbeb;

  height: 100vh;
  width: 100vw;
  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}
