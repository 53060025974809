.footer {
  width: 100%;
  background-color: var(--black);
  z-index: 10;
  position: fixed;
  bottom: 0%;
  left: 0%;
  color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.changeFloorButton {
  cursor: pointer;
  width: 10vw;
  height: 100%;
}

.floor-info-wrapper {
  padding-bottom: 8px;
  min-height: 9vh;

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: height 2s ease-in;
}

.floor-info {
  padding-bottom: 8px;
  width: 100%;
  font-size: 2.5vh;
}

.caps {
  text-transform: uppercase;
}

#floor-info-contents {
  height: 0;
  display: none;
}

.show {
  height: fit-content !important;
  display: block !important;
  border-top: solid 1px var(--white);
  padding-top: 2vh;
}

.title {
  font-size: 2.5vh;
}

.contents {
  margin-bottom: 3vh;
  font-size: 2vh;
}
