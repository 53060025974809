.volvo1 h3 {
  margin-top: 150px !important;
}

.room-name {
  color: var(--mapBackground);
  font-size: 32px;
  margin: 0px;
  z-index: 2;
}

.gps h3 {
  font-size: 24px;
}

.gpm h3 {
  font-size: 24px;
}

.gpv h3 {
  font-size: 24px;
}

.balkong {
  background: var(--mapOutside) !important;
}

.teknologgard {
  background: var(--mapOutside) !important;
}

.innergard {
  background: var(--mapOutside) !important;
}
