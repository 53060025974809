.Map-container {
  /* background-color: #282c34; */

  position: fixed;
  top: 0;
  left: 0;
  margin-top: 8vh;
  margin-bottom: 9vh;
  height: 83vh;
  width: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
}

.Map {
  position: relative;
  width: 500px;
  height: 1350px;
  overflow: visible;
}
